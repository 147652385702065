import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalOutputService {
  private dataSubject = new BehaviorSubject<any>(null);
  public data$: Observable<any> = this.dataSubject.asObservable();

  sendData(data: any) {
    this.dataSubject.next(data);
  }

  clearData() {
    this.dataSubject.next(null);
  }
}
