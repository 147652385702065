import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'app/shared/services/http.service';
import { ModalOutputService } from 'app/shared/services/modalOutput.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modal-add-edit-client',
  templateUrl: './modal-add-edit-client.component.html',
  styleUrls: ['./modal-add-edit-client.component.scss']
})
export class ModalAddEditClientComponent implements OnInit {



  public active = 1;
  public Coupleactive = 1;
  public pay;

  clinician = [
    { id: 0, name: 'All Clinician' },
    { id: 1, name: 'Clinician : Vilnius' },
    { id: 2, name: 'Clinician : Kaunas' },
    { id: 3, name: 'Clinician : Pavilnys', disabled: true },
    { id: 4, name: 'Clinician : Pabradė' },
    { id: 5, name: 'Clinician : Klaipėda' }
  ];

  clients = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys' },
    { id: 4, name: 'Pabradė' },
    { id: 5, name: 'Klaipėda' }
  ];

  insurance = [
    { id: 0, name: 'All Insurance' },
    { id: 1, name: 'Insurance : Vilnius' },
    { id: 2, name: 'Insurance : Kaunas' },
    { id: 3, name: 'Insurance : Pavilnys', disabled: true },
    { id: 4, name: 'Insurance : Pabradė' },
    { id: 5, name: 'Insurance : Klaipėda' }
  ];

  location = [
    { id: 1, name: 'Clinic Visit' },
    { id: 2, name: 'Voice Call' },
    { id: 3, name: 'Video Call' },
  ];

  emailtype = [
    { id: 1, name: 'Home' },
    { id: 2, name: 'Work' },
  ];

  contactrelation = [
    { id: 1, name: 'Parent' },
    { id: 2, name: 'Sibling' },
    { id: 3, name: 'Grand-Parent' },
    { id: 4, name: 'Uncle' },
  ];

  phonetype = [
    { id: 1, name: 'Mobile' },
  ];

  clientforbilling = [
    { id: 1, name: 'Client 1' },
    { id: 2, name: 'Client 2' },
  ];

  status = [
    { id: 0, name: 'All Status' },
    { id: 1, name: 'Active' },
    { id: 2, name: 'In-Active' },
  ];

  ClientFormSubmitted = false;
  btnDisable : boolean = false ;
  emailDisable : boolean = false ;
  btnText : string = "Submit";
  // clientForm: FormGroup;

  tryForm = this.fb.group({
    clientType: [1],
  })

  clientForm = this.fb.group({

    clientId: [ 0 ],
    clinicId: [ 0 ],
    clientType: [ 1 , Validators.required ],
    firstName1: ['', Validators.required],
    lastName1: ['', Validators.required],
    email1: ['', Validators.required],
    emailType1: ['', Validators.required],
    isSendEmailReminder1: [ false , Validators.required],
    phone1: ['', Validators.required],
    phoneType1: ['', Validators.required],
    remainderType1: ['', Validators.required],
    paymentType: ['', Validators.required],
    insuranceId: [ 0 ],
    primaryClinicianId: [ 0 , Validators.required],
    location: ['', Validators.required],
    firstName2: [''],
    lastName2: [''],
    email2: [''],
    emailType2: [''],
    phone2: [''],
    phoneType2: [''],
    remainderType2: [''],
    isSendEmailRemainder2: [ false ],
    primaryClinicianId2: [ 0 ],
    location2: [''],
    relationship: [''],
    isResponsibleForBilling: [ false ],
    billResponsibleClient: [''],
    status: [true]

  });
  
  constructor(
      public activeModal: NgbActiveModal, 
      private fb: FormBuilder,
      private toastr: ToastrService,
      private http: HttpService,
      public cdr: ChangeDetectorRef,
      private modalOutputService: ModalOutputService,
    ) { }

  onSubmit(){

    this.btnText = "Loading...";
    this.btnDisable = true ;
    this.ClientFormSubmitted = true;

    const formData = this.clientForm.value;
    const ActiveClinic = localStorage.getItem('ActiveClinic');
    const ActiveClinicINT = parseInt(ActiveClinic, 10);
    
    formData.clinicId = ActiveClinicINT;

    if (this.clientForm.invalid) {
      this.btnText = "Submit";
      this.btnDisable = false ;
      this.toastr.error('Fill required field in the form');
      return;
    }

    console.log(formData)
  
    this.http.post(`Client/SaveClient`, formData).subscribe((res) =>{
      if(res.status === 1 && res.data === true){

        this.toastr.success('Client Created Successfully !');
        this.modalOutputService.sendData(formData.clientType);
        this.btnText = "Submit";
        this.btnDisable = false ;
        this.activeModal.close();

      }else{
        this.toastr.error(res.message);
        this.activeModal.close();
      }
    },(error)=>{
      this.toastr.error(error.message , error.name );
      this.activeModal.close();
    }
    );

  }

  onBillingTypeChange(e){
    if(e === 'Self-pay'){
      this.clientForm.get("insuranceId").clearValidators();
      this.clientForm.get("insuranceId").updateValueAndValidity();
    }else{
      this.clientForm.controls["insuranceId"].setValidators([Validators.required]);
    }
  }

  onClientTypeChange(e){
    if(e === 1){
      console.log('Client Type 1');
      this.clientForm.get("firstName2").clearValidators();
      this.clientForm.get("firstName2").updateValueAndValidity();

      this.clientForm.get("lastName2").clearValidators();
      this.clientForm.get("lastName2").updateValueAndValidity();

      this.clientForm.get("email2").clearValidators();
      this.clientForm.get("email2").updateValueAndValidity();

      this.clientForm.get("emailType2").clearValidators();
      this.clientForm.get("emailType2").updateValueAndValidity();

      this.clientForm.get("phoneType2").clearValidators();
      this.clientForm.get("phoneType2").updateValueAndValidity();

      this.clientForm.get("phone2").clearValidators();
      this.clientForm.get("phone2").updateValueAndValidity();

      this.clientForm.get("isSendEmailRemainder2").clearValidators();
      this.clientForm.get("isSendEmailRemainder2").updateValueAndValidity();

      this.clientForm.get("remainderType2").clearValidators();
      this.clientForm.get("remainderType2").updateValueAndValidity();

      this.clientForm.get("primaryClinicianId2").clearValidators();
      this.clientForm.get("primaryClinicianId2").updateValueAndValidity();

      this.clientForm.get("location2").clearValidators();
      this.clientForm.get("location2").updateValueAndValidity();

      this.clientForm.get("relationship").clearValidators();
      this.clientForm.get("relationship").updateValueAndValidity();

      this.clientForm.get("isResponsibleForBilling").clearValidators();
      this.clientForm.get("isResponsibleForBilling").updateValueAndValidity();

      this.clientForm.get("billResponsibleClient").clearValidators();
      this.clientForm.get("billResponsibleClient").updateValueAndValidity();
      
    }else if(e === 2){
      console.log('Client Type 2');
      this.clientForm.get("billResponsibleClient").clearValidators();
      this.clientForm.get("billResponsibleClient").updateValueAndValidity();

      this.clientForm.controls["firstName2"].setValidators([Validators.required]);
      this.clientForm.controls["email2"].setValidators([Validators.required]);
      this.clientForm.controls["firstName2"].setValidators([Validators.required]);
      this.clientForm.controls["emailType2"].setValidators([Validators.required]);
      this.clientForm.controls["phoneType2"].setValidators([Validators.required]);
      this.clientForm.controls["phone2"].setValidators([Validators.required]);
      this.clientForm.controls["isSendEmailRemainder2"].setValidators([Validators.required]);
      this.clientForm.controls["remainderType2"].setValidators([Validators.required]);
      this.clientForm.controls["relationship"].setValidators([Validators.required]);
      this.clientForm.controls["isResponsibleForBilling"].setValidators([Validators.required]);

      // this.clientForm.get("otherAddress").clearValidators();
      // this.clientForm.get("otherAddress").updateValueAndValidity();
    }else{
      console.log('Client Type 3');

      this.clientForm.get("isResponsibleForBilling").clearValidators();
      this.clientForm.get("isResponsibleForBilling").updateValueAndValidity();

      this.clientForm.controls["firstName2"].setValidators([Validators.required]);
      this.clientForm.controls["email2"].setValidators([Validators.required]);
      this.clientForm.controls["firstName2"].setValidators([Validators.required]);
      this.clientForm.controls["emailType2"].setValidators([Validators.required]);
      this.clientForm.controls["phoneType2"].setValidators([Validators.required]);
      this.clientForm.controls["phone2"].setValidators([Validators.required]);
      this.clientForm.controls["isSendEmailRemainder2"].setValidators([Validators.required]);
      this.clientForm.controls["remainderType2"].setValidators([Validators.required]);
      this.clientForm.controls["relationship"].setValidators([Validators.required]);
      this.clientForm.controls["billResponsibleClient"].setValidators([Validators.required]);

    }
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
  }

}
