import { Routes, RouterModule } from '@angular/router';
// Route for content layout with sidebar, navbar, and footer.
export const Full_ROUTES: Routes = [
  
  // Clinician
  {
    path: 'clinician-reports',
    loadChildren: () => import('../../EMR-Clinician-Side/clinician-report/clinician-report.module').then(m => m.ClinicianReportModule)
  },
  {
    path: 'clinician-supervisor',
    loadChildren: () => import('../../EMR-Clinician-Side/clinician-supervisor/clinician-supervisor.module').then(m => m.ClinicianSupervisorModule)
  },
  // Clinic Routes
  {
    path: 'insurance',
    loadChildren: () => import('../../insurance/insurance.module').then(m => m.InsuranceModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('../../report/report.module').then(m => m.ReportModule)
  },
  {
    path: 'supervisor',
    loadChildren: () => import('../../supervisor/supervisor.module').then(m => m.SupervisorModule)
  },
  {
    path: 'communication',
    loadChildren: () => import('../../communication/communication.module').then(m => m.CommunicationModule)
  },
  {
    path: 'setting',
    loadChildren: () => import('../../setting/setting.module').then(m => m.SettingModule)
  },
  // Client Routes
  {
    path: 'patient-document',
    loadChildren: () => import('../../EMR-Patient-Side/patient-document/patient-document.module').then(m => m.PatientDocumentModule)
  },
  {
    path: 'patient-billing',
    loadChildren: () => import('../../EMR-Patient-Side/patient-payment-billing/patient-payment-billing.module').then(m => m.PatientPaymentBillingModule)
  },
  {
    path: 'patient-appointment',
    loadChildren: () => import('../../EMR-Patient-Side/patient-appointment/patient-appointment.module').then(m => m.PatientAppointmentModule)
  },
  // Global Admin
  {
    path: 'invoice',
    loadChildren: () => import('../../invoice/invoice.module').then(m => m.InvoiceModule)
  },
  // Default Routes
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('../../pages/full-pages/full-pages.module').then(m => m.FullPagesModule)
  },
  {
    path: 'message',
    loadChildren: () => import('../../chat/chat.module').then(m => m.ChatModule)
  },
  {
    path: 'schedular',
    loadChildren: () => import('../../schedular/schedular.module').then(m => m.SchedularModule)
  },
  {
    path: 'client',
    loadChildren: () => import('../../people/people.module').then(m => m.PeopleModule)
  },
  {
    path: 'clinic',
    loadChildren: () => import('../../clinics/clinics.module').then(m => m.ClinicsModule)
  },
  {
    path: 'template',
    loadChildren: () => import('../../template/template.module').then(m => m.TemplateModule)
  },
];


