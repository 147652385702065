import {
  Component, OnInit, ViewChild, OnDestroy,
  ElementRef, AfterViewInit, ChangeDetectorRef, HostListener
} from "@angular/core";
import { RouteInfo } from './vertical-menu.metadata';
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { customAnimations } from "../animations/custom-animations";
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfigService } from '../services/config.service';
import { Subscription } from 'rxjs';
import { LayoutService } from '../services/layout.service';

@Component({
  selector: "app-sidebar",
  templateUrl: "./vertical-menu.component.html",
  animations: customAnimations
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  public AdminRoutes: RouteInfo[] = [
    { path: '/dashboard/admin', title: 'Dashboard', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    {
      path: '', title: 'Schedule', icon: 'ft-calendar', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
        { path: 'schedular/calender', title: 'Calendar', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/schedular/appointment', title: 'Appointments List', icon: 'ft-arrow-right submenu-icon', class: '', isExternalLink: false, submenu: [] },
        { path: 'schedular/availability', title: 'Availability', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      ]
    },
    // { path: 'calender/', title: 'Calendar', icon: 'ft-calendar', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    {
      path: '', title: 'People', icon: 'ft-user', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
        { path: '/client/view', title: 'Clients', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/client/potential', title: 'Potential Clients', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/client/archive', title: 'Archived Clients', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      ]
    },
    { path: '/clinic', title: 'Clinic', icon: 'fa fa-hospital-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    {
      path: '', title: 'Report', icon: 'fa fa-file-archive-o', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
        { path: '/reports/overtime-balance', title: 'Balance Aging', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/reports/insurance-appointment', title: 'Insurance Appointment', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/reports/billing-information', title: 'Billing Information', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/reports/appointment-details', title: 'Appointment Details', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/reports/client-detail', title: 'Client Detail', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/reports/claims', title: 'Denied Claims', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/reports/payment-transaction', title: 'Payment Transaction', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/reports/payout', title: 'Payout', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/reports/documentation', title: 'Documentation', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/reports/activity-log', title: 'Activity Log', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      ]
    },
    { path: '/insurance/view', title: 'Insurance', icon: 'fa fa-file-text-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/communication/view', title: 'Communication', icon: 'fa fa-commenting', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/supervisor', title: 'Supervisor', icon: 'ft-briefcase', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/invoice', title: 'Invoice', icon: 'fa fa-files-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/message', title: 'Message', icon: 'ft-message-square', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '', title: 'Settings', icon: 'fa fa-cogs', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      { path: '/setting/billing-services', title: 'Billing & Services', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/setting/calender-setting', title: 'Calendar', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/setting/import', title: 'Import Data', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/setting/task', title: 'Tasks', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/setting/client-portal', title: 'Client Portal', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/setting/clinic-location', title: 'Clinic Location', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '', title: 'Template Designer', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
        { path: '/setting/template-designer/communication', title: 'Communication', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        // { path: '/setting/template-designer/pdf-template', title: 'Pdf Template', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/setting/template-designer/clinical', title: 'Clinical Doc', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/setting/template-designer/macro', title: 'Macro Fields', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      ] },
    ] },
  ];

  public OfficeRoutes: RouteInfo[] = [
    { path: '/dashboard/office', title: 'Dashboard', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    {
      path: '', title: 'Schedule', icon: 'ft-calendar', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
        { path: 'schedular/calender', title: 'Calendar', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/schedular/appointment', title: 'Appointments List', icon: 'ft-arrow-right submenu-icon', class: '', isExternalLink: false, submenu: [] },
        { path: 'schedular/availability', title: 'Availability', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      ]
    },
    // { path: 'calender/', title: 'Calendar', icon: 'ft-calendar', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    {
      path: '', title: 'People', icon: 'ft-user', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
        { path: '/client/view', title: 'Clients', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/client/potential', title: 'Potential Clients', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/client/archive', title: 'Archived Clients', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      ]
    },
    { path: '/clinic', title: 'Clinic', icon: 'fa fa-hospital-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    {
      path: '', title: 'Report', icon: 'fa fa-file-archive-o', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
        { path: '/reports/overtime-balance', title: 'Balance Aging', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/reports/insurance-appointment', title: 'Insurance Appointment', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/reports/billing-information', title: 'Billing Information', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/reports/appointment-details', title: 'Appointment Details', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/reports/client-detail', title: 'Client Detail', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/reports/claims', title: 'Denied Claims', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/reports/payment-transaction', title: 'Payment Transaction', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/reports/payout', title: 'Payout', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/reports/documentation', title: 'Documentation', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/reports/activity-log', title: 'Activity Log', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      ]
    },
    { path: '/insurance/view', title: 'Insurance', icon: 'fa fa-file-text-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/communication/view', title: 'Communication', icon: 'fa fa-commenting', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/message', title: 'Message', icon: 'ft-message-square', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  ];

  public ClinicianRoutes: RouteInfo[] = [
    { path: '/dashboard/clinician', title: 'Dashboard', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    {
      path: '', title: 'Schedule', icon: 'ft-calendar', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
        { path: 'schedular/calender', title: 'Calendar', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/schedular/appointment', title: 'Appointments List', icon: 'ft-arrow-right submenu-icon', class: '', isExternalLink: false, submenu: [] },
        { path: 'schedular/availability', title: 'Availability', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      ]
    },
    // { path: 'calender/', title: 'Calendar', icon: 'ft-calendar', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    {
      path: '', title: 'People', icon: 'ft-user', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
        { path: '/client/view', title: 'Clients', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/client/potential', title: 'Potential Clients', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/client/archive', title: 'Archived Clients', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      ]
    },
    {
      path: '', title: 'Report', icon: 'fa fa-file-archive-o', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
        { path: '/clinician-reports/appointment-details', title: 'Appointment Details', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/clinician-reports/income-report', title: 'Income Report', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/clinician-reports/client-detail', title: 'Client Detail', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/clinician-reports/documentation', title: 'Documentation', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/clinician-reports/activity-log', title: 'Activity Log', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      ]
    },
    { path: '/clinician-supervisor', title: 'Supervisor', icon: 'ft-briefcase', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/message', title: 'Message', icon: 'ft-message-square', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  ];

  public ClinicRoutes: RouteInfo[] = [
    { path: '/dashboard/clinic', title: 'Dashboard', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    {
      path: '', title: 'Schedule', icon: 'ft-calendar', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
        { path: 'schedular/calender', title: 'Calendar', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/schedular/appointment', title: 'Appointments List', icon: 'ft-arrow-right submenu-icon', class: '', isExternalLink: false, submenu: [] },
        { path: 'schedular/availability', title: 'Availability', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      ]
    },
    // { path: 'calender/', title: 'Calendar', icon: 'ft-calendar', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    {
      path: '', title: 'People', icon: 'ft-user', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
        { path: '/client/view', title: 'Clients', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/client/potential', title: 'Potential Clients', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/client/archive', title: 'Archived Clients', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      ]
    },
    { path: '/insurance/view', title: 'Insurance', icon: 'fa fa-file-text-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    {
      path: '', title: 'Report', icon: 'fa fa-file-archive-o', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
        { path: '/reports/overtime-balance', title: 'Balance Aging', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/reports/insurance-appointment', title: 'Insurance Appointment', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/reports/billing-information', title: 'Billing Information', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/reports/appointment-details', title: 'Appointment Details', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/reports/income-report', title: 'Income Report', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/reports/client-detail', title: 'Client Detail', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/reports/claims', title: 'Denied Claims', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/reports/payment-transaction', title: 'Payment Transaction', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/reports/payout', title: 'Payout', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/reports/documentation', title: 'Documentation', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/reports/activity-log', title: 'Activity Log', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      ]
    },
    { path: '/communication/view', title: 'Communication', icon: 'fa fa-commenting', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/supervisor', title: 'Supervisor', icon: 'ft-briefcase', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/message', title: 'Message', icon: 'ft-message-square', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/clinic', title: 'Clinic', icon: 'fa fa-hospital-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '', title: 'Settings', icon: 'fa fa-cogs', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      { path: '/setting/billing-services', title: 'Billing & Services', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/setting/calender-setting', title: 'Calendar', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/setting/task', title: 'Tasks', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/setting/client-portal', title: 'Client Portal', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/setting/clinic-location', title: 'Clinic Location', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '', title: 'Template Designer', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
        { path: '/setting/template-designer/communication', title: 'Communication', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/setting/template-designer/clinical', title: 'Clinical Doc', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/setting/template-designer/macro', title: 'Macro Fields', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      ] },
    ] },
  ];

  public ClientRoutes: RouteInfo[] = [
    { path: '/dashboard/patient', title: 'Dashboard', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/patient-document/view', title: 'Documents', icon: 'ft-file-text', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/patient-billing/view', title: 'Payments & Billing', icon: 'fa fa-credit-card', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    {
      path: '', title: 'Appointment', icon: 'fa fa-calendar', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
        { path: '/patient-appointment/view', title: 'Records', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/patient-appointment/booking', title: 'Booking', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      ]
    },
    { path: '/message', title: 'Message', icon: 'ft-message-square', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  ];

  @ViewChild('toggleIcon') toggleIcon: ElementRef;
  public menuItems: any[];
  public showBarRoutes: any[];
  public showAwailableRoutes: any[];
  userRole = "";
  level: number = 0;
  logoUrl = 'assets/img/logo.png';
  public config: any = {};
  protected innerWidth: any;
  layoutSub: Subscription;
  configSub: Subscription;
  perfectScrollbarEnable = true;
  collapseSidebar = false;
  resizeTimeout;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private deviceService: DeviceDetectorService
  ) {
    this.userRole = JSON.parse(localStorage.getItem('user')).roleName;
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    this.isTouchDevice();

    if(this.userRole === 'Clinician'){
      console.log('Is Clinician Admin');
      this.showBarRoutes = this.ClinicianRoutes;
    }else if(this.userRole === 'Clinic Admin'){
      console.log('Is Clinic Admin');
      this.showBarRoutes = this.ClinicRoutes;
    }else if(this.userRole === 'Client'){
      console.log('Is Client Admin');
      this.showBarRoutes = this.ClientRoutes;
    }else if(this.userRole == 'Global Admin'){
      console.log('Is Global Admin');
      this.showBarRoutes = this.AdminRoutes;
    }else if(this.userRole === 'Office Manager'){
      console.log('Is Office Manager');
      this.showBarRoutes = this.OfficeRoutes;
    }
    this.showAwailableRoutes = this.showBarRoutes;
  }

  ngOnInit() {
    this.menuItems = this.showAwailableRoutes;
  }

  ngAfterViewInit() {

    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    });

    this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
      collapse => {
        if (this.config.layout.menuPosition === "Side") {
          this.collapseSidebar = collapse;
        }
      });

  }


  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
      if (this.resizeTimeout) {
          clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout((() => {
        this.innerWidth = event.target.innerWidth;
          this.loadLayout();
      }).bind(this), 500);
  }

  loadLayout() {

    if (this.config.layout.menuPosition === "Side") { // Vertical Menu{
      this.menuItems = this.showAwailableRoutes;
    }




    if (this.config.layout.sidebar.backgroundColor === 'white') {
      this.logoUrl = 'assets/img/logo-dark.png';
    }
    else {
      this.logoUrl = 'assets/img/logo.png';
    }

    if(this.config.layout.sidebar.collapsed) {
      this.collapseSidebar = true;
    }
    else {
      this.collapseSidebar = false;
    }
  }

  toggleSidebar() {
    let conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    this.configService.applyTemplateConfigChange({ layout: conf.layout });

    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }

  fireRefreshEventOnWindow = function () {
    const evt = document.createEvent("HTMLEvents");
    evt.initEvent("resize", true, false);
    window.dispatchEvent(evt);
  };

  CloseSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  isTouchDevice() {

    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      this.perfectScrollbarEnable = false;
    }
    else {
      this.perfectScrollbarEnable = true;
    }

  }


  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }

  }

}
