import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs'
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable()
export class HttpService {

    private baseUrl: string;
    public spinner$: Subject<any>;

    constructor(private http: HttpClient, private router: Router) {
        this.baseUrl = `${environment.PROTOCOL}://${environment.baseURL}/`;
        this.spinner$ = new Subject<any>();
    }

    private getHeaders(): HttpHeaders {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        let user = localStorage.getItem('user');

        if (user) {
            // HttpHeaders are immutable, set() method returns new instance of HttpHeaders
            headers = headers.set('Authorization', `Bearer ${JSON.parse(user).token}`);
        }

        return headers;
    }

    public onError(error: any): Promise<any> {
        if (error.status === 401 || error.status === 403) {
            this.router.navigate(['/login']);
        }
        return Promise.reject(error);
    }

    /**
     * Executes GET request for specified url.
     * @param url Url to execute.
     */
    get(url: string): Observable<any> {
        return this.http.get(`${this.baseUrl}${url}`, {
            headers: this.getHeaders()
        })
    }

    /**
     * Executes POST request with specified url and data
     * @param url Request Url.
     * @param data Post data.
     */
    post(url: string, data: any): Observable<any> {
        return this.http.post(`${this.baseUrl}${url}`, data, {
            headers: this.getHeaders()
        })
    }
    /**
     * Executes POST request with specified url and data
     * @param url Request Url.
     * @param deleteId delete data id.
     */
    delele(url: string, deleteId: any): Observable<any> {
        return this.http.delete(`${this.baseUrl}${url}/${deleteId}`, {
            headers: this.getHeaders()
        })
    }


    // uploadfile(url: string , file: File) {
    //     let formParams = new FormData();
    //     formParams.append('file', file)
    //     return this.http.post(`${this.baseUrl}${url}`, formParams)
    //   }

    public uploadfile(url: string , file: File) {
      let formParams = new FormData();
      formParams.append('file', file)
      return this.http.post(`${this.baseUrl}${url}`, formParams)
    }


    private subject = new Subject<any>();
    private subjectCalender = new Subject<any>();

    sendMessage(message: any) {
        this.subject.next(message);
    }

    sendMessageCalender(message: string) {
        this.subjectCalender.next({ type: message });
    }
    getMessageCalender(): Observable<any> {
        return this.subjectCalender.asObservable();
    }

    clearMessages() {
        this.subject.next();
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }

    showSpinner() {
        this.spinner$.next(true);
    }

    hideSpinner() {
        this.spinner$.next(false);
    }
}