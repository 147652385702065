<div class="modal-header">
	<h4 class="modal-title" id="modal-basic-title">Add Client</h4>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
  <span aria-hidden="true">&times;</span>
</button>
</div>

<!-- <form [formGroup]="tryForm">
  <input type="radio" [value]="1" formControlName="clientType"> sad <br />
  <input type="radio" [value]="2" formControlName="clientType"> fg <br />
  <input type="radio" [value]="3" formControlName="clientType">ytyt

</form> -->

<form [formGroup]="clientForm" (submit)="onSubmit()">
<div class="modal-body">
  <div class="form-body pt-2 pl-2 pr-2">
    <div class="form-row">
      <div class="col-md-12 border-bottom">
        <label class="h6 pb-2">Client Type :</label>
        <div class="form-group">
          <ul class="list-unstyled mb-0">
            <li class="d-inline-block mr-2">
              <div class="radio radio-success">
                <input type="radio" id="Adult" [value]="1" name="clientType" formControlName="clientType" (click)="onClientTypeChange(1)">
                <label for="Adult">Adult</label>
              </div>
            </li>
            <li class="d-inline-block mr-2">
              <div class="radio radio-success">
                <input type="radio" id="Minor" [value]="2" name="clientType" formControlName="clientType" (click)="onClientTypeChange(2)">
                <label for="Minor">Minor</label>
              </div>
            </li>
            <li class="d-inline-block mr-2">
              <div class="radio radio-success">
                <input type="radio" id="Couple" [value]="3" name="clientType" formControlName="clientType" (click)="onClientTypeChange(3)">
                <label for="Couple">Couple</label>
              </div>
            </li>
          </ul>
        </div>
        <div *ngIf=" ClientFormSubmitted && (clientForm.get('clientType').invalid || 
          clientForm.get('clientType').errors?.required)" 
          class="help-block mt-1 text-danger"> 
            <i class="ft-alert-circle align-middle"></i> 
            This is required
        </div>
      </div>
    </div>
    <div class="p-2" *ngIf="this.clientForm.get('clientType').value === 1">
      <div class="form-row align-items-start mb-2">
        <div class="col-md-6">
          <div class="form-group mb-2">
            <label for="firstName1">Legal first name</label>
            <input type="text" class="form-control" id="firstName1" name="firstName1" formControlName="firstName1" 
            [ngClass]="{ 
              'is-invalid': ClientFormSubmitted && clientForm.get('firstName1').invalid,
              'is-valid': ClientFormSubmitted && !clientForm.get('firstName1').invalid
            }"/>

            <div class="help-block mt-1 text-danger" 
            *ngIf="ClientFormSubmitted && (clientForm.get('firstName1').invalid 
              || clientForm.get('firstName1').errors?.required)"> 
                <i class="ft-alert-circle align-middle"></i> 
                This is required 
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group mb-2">
            <label for="lastName1">Legal last name</label>
            <input type="text" class="form-control" id="lastName1" name="lastName1" formControlName="lastName1"
            [ngClass]="{ 
              'is-invalid': ClientFormSubmitted && clientForm.get('lastName1').invalid,
              'is-valid': ClientFormSubmitted && !clientForm.get('lastName1').invalid
            }"/>

            <div class="help-block mt-1 text-danger" 
            *ngIf="ClientFormSubmitted && (clientForm.get('lastName1').invalid 
              || clientForm.get('lastName1').errors?.required)"> 
                <i class="ft-alert-circle align-middle"></i> 
                This is required 
            </div>
          </div>
        </div>
      </div>
      <div class="form-row align-items-start mb-2">
        <div class="col-md-8">
          <div class="form-group mb-2">
            <label for="email1">Email address</label>
            <input type="email" class="form-control" id="email1" name="email1" formControlName="email1" 
            [ngClass]="{ 
              'is-invalid': ClientFormSubmitted && clientForm.get('email1').invalid,
              'is-valid': ClientFormSubmitted && !clientForm.get('email1').invalid
            }"/>
            
            <p class="text-muted font-small-3">
              To grant Client Portal access, enter an email address
            </p>

            <div class="help-block mt-1 text-danger" 
            *ngIf="ClientFormSubmitted && (clientForm.get('email1').invalid 
              || clientForm.get('email1').errors?.required)"> 
                <i class="ft-alert-circle align-middle"></i> 
                This is required 
            </div>
            
          </div>
        </div>
        <div class="col-md-4">
          <label for="emailType1">Type</label>
          <div class="form-group mb-2">
            <ng-select id="emailType1" name="emailType1" formControlName="emailType1"
            class="validate" [ngClass]="{ 
              'is-invalid ng-touched': ClientFormSubmitted && clientForm.get('emailType1').invalid,
              'is-valid': ClientFormSubmitted && !clientForm.get('emailType1').invalid
            }">
              <ng-option value="Home">Home</ng-option>
              <ng-option value="Work">Work</ng-option>
            </ng-select>

            <div class="help-block mt-1 text-danger" 
            *ngIf="ClientFormSubmitted && (clientForm.get('emailType1').invalid 
              || clientForm.get('emailType1').errors?.required)"> 
                <i class="ft-alert-circle align-middle"></i> 
                This is required 
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="checkbox mb-2">
            <input class="checkbox" type="checkbox" id="isSendEmailReminder1" name="isSendEmailReminder1" formControlName="isSendEmailReminder1"/>
            <label class="form-check-label" for="isSendEmailReminder1" >
              Send an email reminder 24 hours before the appointment
            </label>
          </div>
        </div>
      </div>          
      <div class="form-row align-items-start mb-2">
        <div class="col-md-4">
          <label for="phone1">Phone Number</label>
          <div class="form-group mb-2">
            <input type="phone" class="form-control" name="phone1" id="phone1" formControlName="phone1" 
            [ngClass]="{ 
              'is-invalid': ClientFormSubmitted && clientForm.get('phone1').invalid,
              'is-valid': ClientFormSubmitted && !clientForm.get('phone1').invalid
            }"/>

            <div class="help-block mt-1 text-danger" 
            *ngIf="ClientFormSubmitted && (clientForm.get('phone1').invalid 
              || clientForm.get('phone1').errors?.required)"> 
                <i class="ft-alert-circle align-middle"></i> 
                This is required 
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group mb-2">
            <label for="phoneType1">Type</label>
            <ng-select name="phoneType1" id="phoneType1" formControlName="phoneType1" 
            class="validate" [ngClass]="{ 
              'is-invalid ng-touched': ClientFormSubmitted && clientForm.get('phoneType1').invalid,
              'is-valid': ClientFormSubmitted && !clientForm.get('phoneType1').invalid
            }">
              <ng-option value="Mobile">Mobile</ng-option>
            </ng-select>

            <div class="help-block mt-1 text-danger" 
            *ngIf="ClientFormSubmitted && (clientForm.get('phoneType1').invalid 
              || clientForm.get('phoneType1').errors?.required)"> 
                <i class="ft-alert-circle align-middle"></i> 
                This is required 
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="radio mb-1">
            <input class="radio" type="radio" name="remainderType1" id="textmessage" value="textmessage" formControlName="remainderType1" 
            [ngClass]="{ 
              'is-invalid': ClientFormSubmitted && clientForm.get('remainderType1').invalid,
              'is-valid': ClientFormSubmitted && !clientForm.get('remainderType1').invalid
            }"/>
            <label class="form-check-label" for="textmessage">
              Send a text message reminder 24 hours before appointment
            </label>
          </div>
          <div class="radio">
            <input class="radio" type="radio" name="remainderType1" id="voicemessage" value="voicemessage" formControlName="remainderType1"
            [ngClass]="{ 
              'is-invalid': ClientFormSubmitted && clientForm.get('remainderType1').invalid,
              'is-valid': ClientFormSubmitted && !clientForm.get('remainderType1').invalid
            }"/>
            <label class="form-check-label" for="voicemessage">
              Send a voice message reminder 24 hours before appointment
            </label>
          </div>
          <div class="help-block mt-1 text-danger" 
            *ngIf="ClientFormSubmitted && (clientForm.get('remainderType1').invalid 
              || clientForm.get('remainderType1').errors?.required)"> 
                <i class="ft-alert-circle align-middle"></i> 
                Must select one of the above 
          </div>
        </div>
      </div>
      <div class="form-row align-items-start">
        <div class="col-md-12">
          <label class="mb-1" for="paymentType">Billing Type</label>
          <div class="help-block mt-1 mb-2 text-danger" 
            *ngIf="ClientFormSubmitted && (clientForm.get('paymentType').invalid 
              || clientForm.get('paymentType').errors?.required)"> 
                <i class="ft-alert-circle align-middle"></i> 
                Must select one of the Below 
          </div>
          <div class="radio mb-1">
            <input class="radio" type="radio" name="paymentType" id="Self-pay" value="Self-pay" formControlName="paymentType" (click)="onBillingTypeChange('Self-pay')"
            [ngClass]="{ 
              'is-invalid': ClientFormSubmitted && clientForm.get('paymentType').invalid,
              'is-valid': ClientFormSubmitted && !clientForm.get('paymentType').invalid
            }"/>
            <label class="form-check-label" for="Self-pay">
              Self-pay
            </label>
          </div>
          <div class="radio mb-3">
            <input class="radio" type="radio" name="paymentType" id="insurance" value="insurance" formControlName="paymentType" (click)="onBillingTypeChange('insurance')"
            [ngClass]="{ 
              'is-invalid': ClientFormSubmitted && clientForm.get('paymentType').invalid,
              'is-valid': ClientFormSubmitted && !clientForm.get('paymentType').invalid
            }"/>
            <label class="form-check-label" for="insurance">
              Insurance
            </label>
          </div>
        </div>
        <div class="col-md-12 mb-3 border-bottom" *ngIf="this.clientForm.get('paymentType').value === 'insurance' ">
          <!-- Will Apper when clicked on insurance radio button -->
          <div class="mb-3">
            <ng-select appendTo="body" name="insuranceId" id="insuranceId" formControlName="insuranceId" 
            class="validate" [ngClass]="{ 
              'is-invalid ng-touched': ClientFormSubmitted && clientForm.get('insuranceId').invalid,
              'is-valid': ClientFormSubmitted && !clientForm.get('insuranceId').invalid
            }">
              <ng-option [value]="1">Insurance 1</ng-option>
              <ng-option [value]="2">Insurance 2</ng-option>
              <ng-option [value]="3">Insurance 3</ng-option>
            </ng-select>

            <div class="help-block mt-1 text-danger" 
            *ngIf="ClientFormSubmitted && (clientForm.get('insuranceId').invalid 
              || clientForm.get('insuranceId').errors?.required)"> 
                <i class="ft-alert-circle align-middle"></i> 
                This is required 
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group mb-2">
            <label for="primaryClinicianId">Primary Clinician</label>
            <ng-select appendTo="body" name="primaryClinicianId" id="primaryClinicianId" formControlName="primaryClinicianId" 
            class="validate" [ngClass]="{ 
              'is-invalid ng-touched': ClientFormSubmitted && clientForm.get('primaryClinicianId').invalid,
              'is-valid': ClientFormSubmitted && !clientForm.get('primaryClinicianId').invalid
            }">
              <ng-option [value]="1">Clinician 1</ng-option>
              <ng-option [value]="2">Clinician 2</ng-option>
              <ng-option [value]="3">Clinician 3</ng-option>
            </ng-select>

            <div class="help-block mt-1 text-danger" 
            *ngIf="ClientFormSubmitted && (clientForm.get('primaryClinicianId').invalid 
              || clientForm.get('primaryClinicianId').errors?.required)"> 
                <i class="ft-alert-circle align-middle"></i> 
                This is required 
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group mb-2">
            <label for="location">Location</label>
            <ng-select appendTo="body" name="location" id="location" formControlName="location" 
            class="validate" [ngClass]="{ 
              'is-invalid ng-touched': ClientFormSubmitted && clientForm.get('location').invalid,
              'is-valid': ClientFormSubmitted && !clientForm.get('location').invalid
            }">
              <ng-option value="Clinic Visit">Clinic Visit</ng-option>
              <ng-option value="Voice Call">Voice Call</ng-option>
              <ng-option value="Video Call">Video Call</ng-option>
            </ng-select>

            <div class="help-block mt-1 text-danger" 
            *ngIf="ClientFormSubmitted && (clientForm.get('location').invalid 
              || clientForm.get('location').errors?.required)"> 
                <i class="ft-alert-circle align-middle"></i> 
                This is required 
            </div>
          </div>
        </div>
      </div>
    </div> 
    <div class="mt-0 pt-0 pl-2 pr-2 pb-2" *ngIf="this.clientForm.get('clientType').value === 2">
      <ul ngbNav #basicnav="ngbNav" [(activeId)]="active" class="nav-tabs border-0 flx justify-content-end">
        <li [ngbNavItem]="1">
            <a ngbNavLink class="text-uppercase text-dark">Client</a>
            <ng-template ngbNavContent>
              <div class="form-row align-items-start mb-2">
                <div class="col-md-6">
                  <div class="form-group mb-2">
                    <label for="firstName1">Legal first name</label>
                    <input type="text" class="form-control" id="firstName1" name="firstName1" formControlName="firstName1" 
                    [ngClass]="{ 
                      'is-invalid': ClientFormSubmitted && clientForm.get('firstName1').invalid,
                      'is-valid': ClientFormSubmitted && !clientForm.get('firstName1').invalid
                    }"/>
        
                    <div class="help-block mt-1 text-danger" 
                    *ngIf="ClientFormSubmitted && (clientForm.get('firstName1').invalid 
                      || clientForm.get('firstName1').errors?.required)"> 
                        <i class="ft-alert-circle align-middle"></i> 
                        This is required 
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-2">
                    <label for="lastName1">Legal last name</label>
                    <input type="text" class="form-control" id="lastName1" name="lastName1" formControlName="lastName1"
                    [ngClass]="{ 
                      'is-invalid': ClientFormSubmitted && clientForm.get('lastName1').invalid,
                      'is-valid': ClientFormSubmitted && !clientForm.get('lastName1').invalid
                    }"/>
        
                    <div class="help-block mt-1 text-danger" 
                    *ngIf="ClientFormSubmitted && (clientForm.get('lastName1').invalid 
                      || clientForm.get('lastName1').errors?.required)"> 
                        <i class="ft-alert-circle align-middle"></i> 
                        This is required 
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row align-items-start mb-2">
                <div class="col-md-8">
                  <div class="form-group mb-2">
                    <label for="email1">Email address</label>
                    <input type="email" class="form-control" id="email1" name="email1" formControlName="email1" 
                    [ngClass]="{ 
                      'is-invalid': ClientFormSubmitted && clientForm.get('email1').invalid,
                      'is-valid': ClientFormSubmitted && !clientForm.get('email1').invalid
                    }"/>
                    
                    <p class="text-muted font-small-3">
                      To grant Client Portal access, enter an email address
                    </p>
        
                    <div class="help-block mt-1 text-danger" 
                    *ngIf="ClientFormSubmitted && (clientForm.get('email1').invalid 
                      || clientForm.get('email1').errors?.required)"> 
                        <i class="ft-alert-circle align-middle"></i> 
                        This is required 
                    </div>
                    
                  </div>
                </div>
                <div class="col-md-4">
                  <label for="emailType1">Type</label>
                  <div class="form-group mb-2">
                    <ng-select id="emailType1" name="emailType1" formControlName="emailType1"
                    class="validate" [ngClass]="{ 
                      'is-invalid ng-touched': ClientFormSubmitted && clientForm.get('emailType1').invalid,
                      'is-valid': ClientFormSubmitted && !clientForm.get('emailType1').invalid
                    }">
                      <ng-option value="Home">Home</ng-option>
                      <ng-option value="Work">Work</ng-option>
                    </ng-select>
        
                    <div class="help-block mt-1 text-danger" 
                    *ngIf="ClientFormSubmitted && (clientForm.get('emailType1').invalid 
                      || clientForm.get('emailType1').errors?.required)"> 
                        <i class="ft-alert-circle align-middle"></i> 
                        This is required 
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="checkbox mb-2">
                    <input class="checkbox" type="checkbox" id="isSendEmailReminder1" name="isSendEmailReminder1" formControlName="isSendEmailReminder1"/>
                    <label class="form-check-label" for="isSendEmailReminder1" >
                      Send an email reminder 24 hours before the appointment
                    </label>
                  </div>
                </div>
              </div>          
              <div class="form-row align-items-start mb-2">
                <div class="col-md-4">
                  <label for="phone1">Phone Number</label>
                  <div class="form-group mb-2">
                    <input type="phone" class="form-control" name="phone1" id="phone1" formControlName="phone1" 
                    [ngClass]="{ 
                      'is-invalid': ClientFormSubmitted && clientForm.get('phone1').invalid,
                      'is-valid': ClientFormSubmitted && !clientForm.get('phone1').invalid
                    }"/>
        
                    <div class="help-block mt-1 text-danger" 
                    *ngIf="ClientFormSubmitted && (clientForm.get('phone1').invalid 
                      || clientForm.get('phone1').errors?.required)"> 
                        <i class="ft-alert-circle align-middle"></i> 
                        This is required 
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group mb-2">
                    <label for="phoneType1">Type</label>
                    <ng-select name="phoneType1" id="phoneType1" formControlName="phoneType1" 
                    class="validate" [ngClass]="{ 
                      'is-invalid ng-touched': ClientFormSubmitted && clientForm.get('phoneType1').invalid,
                      'is-valid': ClientFormSubmitted && !clientForm.get('phoneType1').invalid
                    }">
                      <ng-option value="Mobile">Mobile</ng-option>
                    </ng-select>
        
                    <div class="help-block mt-1 text-danger" 
                    *ngIf="ClientFormSubmitted && (clientForm.get('phoneType1').invalid 
                      || clientForm.get('phoneType1').errors?.required)"> 
                        <i class="ft-alert-circle align-middle"></i> 
                        This is required 
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="radio mb-1">
                    <input class="radio" type="radio" name="remainderType1" id="textmessage" value="textmessage" formControlName="remainderType1" 
                    [ngClass]="{ 
                      'is-invalid': ClientFormSubmitted && clientForm.get('remainderType1').invalid,
                      'is-valid': ClientFormSubmitted && !clientForm.get('remainderType1').invalid
                    }"/>
                    <label class="form-check-label" for="textmessage">
                      Send a text message reminder 24 hours before appointment
                    </label>
                  </div>
                  <div class="radio">
                    <input class="radio" type="radio" name="remainderType1" id="voicemessage" value="voicemessage" formControlName="remainderType1"
                    [ngClass]="{ 
                      'is-invalid': ClientFormSubmitted && clientForm.get('remainderType1').invalid,
                      'is-valid': ClientFormSubmitted && !clientForm.get('remainderType1').invalid
                    }"/>
                    <label class="form-check-label" for="voicemessage">
                      Send a voice message reminder 24 hours before appointment
                    </label>
                  </div>
                  <div class="help-block mt-1 text-danger" 
                    *ngIf="ClientFormSubmitted && (clientForm.get('remainderType1').invalid 
                      || clientForm.get('remainderType1').errors?.required)"> 
                        <i class="ft-alert-circle align-middle"></i> 
                        Must select one of the above 
                  </div>
                </div>
              </div>
              <div class="form-row align-items-start">
                <div class="col-md-12">
                  <label class="mb-1" for="paymentType">Billing Type</label>
                  <div class="help-block mt-1 mb-2 text-danger" 
                    *ngIf="ClientFormSubmitted && (clientForm.get('paymentType').invalid 
                      || clientForm.get('paymentType').errors?.required)"> 
                        <i class="ft-alert-circle align-middle"></i> 
                        Must select one of the Below 
                  </div>
                  <div class="radio mb-1">
                    <input class="radio" type="radio" name="paymentType" id="Self-pay" value="Self-pay" formControlName="paymentType" 
                    [ngClass]="{ 
                      'is-invalid': ClientFormSubmitted && clientForm.get('paymentType').invalid,
                      'is-valid': ClientFormSubmitted && !clientForm.get('paymentType').invalid
                    }"/>
                    <label class="form-check-label" for="Self-pay">
                      Self-pay
                    </label>
                  </div>
                  <div class="radio mb-3">
                    <input class="radio" type="radio" name="paymentType" id="insurance" value="insurance" formControlName="paymentType" 
                    [ngClass]="{ 
                      'is-invalid': ClientFormSubmitted && clientForm.get('paymentType').invalid,
                      'is-valid': ClientFormSubmitted && !clientForm.get('paymentType').invalid
                    }"/>
                    <label class="form-check-label" for="insurance">
                      Insurance
                    </label>
                  </div>
                </div>
                <div class="col-md-12 mb-3 border-bottom" *ngIf="this.clientForm.get('paymentType').value === 'insurance' ">
                  <!-- Will Apper when clicked on insurance radio button -->
                  <div class="mb-3">
                    <ng-select appendTo="body" name="insuranceId" id="insuranceId" formControlName="insuranceId" 
                    class="validate" [ngClass]="{ 
                      'is-invalid ng-touched': ClientFormSubmitted && clientForm.get('insuranceId').invalid,
                      'is-valid': ClientFormSubmitted && !clientForm.get('insuranceId').invalid
                    }">
                      <ng-option [value]="1">Insurance 1</ng-option>
                      <ng-option [value]="2">Insurance 2</ng-option>
                      <ng-option [value]="3">Insurance 3</ng-option>
                    </ng-select>
        
                    <div class="help-block mt-1 text-danger" 
                    *ngIf="ClientFormSubmitted && (clientForm.get('insuranceId').invalid 
                      || clientForm.get('insuranceId').errors?.required)"> 
                        <i class="ft-alert-circle align-middle"></i> 
                        This is required 
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-2">
                    <label for="primaryClinicianId">Primary Clinician</label>
                    <ng-select appendTo="body" name="primaryClinicianId" id="primaryClinicianId" formControlName="primaryClinicianId" 
                    class="validate" [ngClass]="{ 
                      'is-invalid ng-touched': ClientFormSubmitted && clientForm.get('primaryClinicianId').invalid,
                      'is-valid': ClientFormSubmitted && !clientForm.get('primaryClinicianId').invalid
                    }">
                      <ng-option [value]="1">Clinician 1</ng-option>
                      <ng-option [value]="2">Clinician 2</ng-option>
                      <ng-option [value]="3">Clinician 3</ng-option>
                    </ng-select>
        
                    <div class="help-block mt-1 text-danger" 
                    *ngIf="ClientFormSubmitted && (clientForm.get('primaryClinicianId').invalid 
                      || clientForm.get('primaryClinicianId').errors?.required)"> 
                        <i class="ft-alert-circle align-middle"></i> 
                        This is required 
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-2">
                    <label for="location">Location</label>
                    <ng-select appendTo="body" name="location" id="location" formControlName="location" 
                    class="validate" [ngClass]="{ 
                      'is-invalid ng-touched': ClientFormSubmitted && clientForm.get('location').invalid,
                      'is-valid': ClientFormSubmitted && !clientForm.get('location').invalid
                    }">
                      <ng-option value="Clinic Visit">Clinic Visit</ng-option>
                      <ng-option value="Voice Call">Voice Call</ng-option>
                      <ng-option value="Video Call">Video Call</ng-option>
                    </ng-select>
        
                    <div class="help-block mt-1 text-danger" 
                    *ngIf="ClientFormSubmitted && (clientForm.get('location').invalid 
                      || clientForm.get('location').errors?.required)"> 
                        <i class="ft-alert-circle align-middle"></i> 
                        This is required 
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
        </li>
        <li [ngbNavItem]="2">
            <a ngbNavLink class="text-uppercase text-dark">Contact</a>
            <ng-template ngbNavContent>
              <div class="form-row mb-2">
                <div class="col-md-6">
                  <div class="form-group mb-2">
                    <label for="firstName2">Contact's Legal first name</label>
                    <input type="text" class="form-control" name="firstName2" id="firstName2" formControlName="firstName2" 
                    [ngClass]="{ 
                      'is-invalid': ClientFormSubmitted && clientForm.get('firstName2').invalid,
                      'is-valid': ClientFormSubmitted && !clientForm.get('firstName2').invalid
                    }"/>

                    <div class="help-block mt-1 text-danger" 
                    *ngIf="ClientFormSubmitted && (clientForm.get('firstName2').invalid 
                      || clientForm.get('firstName2').errors?.required)"> 
                        <i class="ft-alert-circle align-middle"></i> 
                        This is required 
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-2">
                    <label for="lastName2">Contact's Legal last name</label>
                    <input type="text" class="form-control" name="lastName2" id="lastName2" formControlName="lastName2"
                    [ngClass]="{ 
                      'is-invalid': ClientFormSubmitted && clientForm.get('lastName2').invalid,
                      'is-valid': ClientFormSubmitted && !clientForm.get('lastName2').invalid
                    }"/>

                    <div class="help-block mt-1 text-danger" 
                    *ngIf="ClientFormSubmitted && (clientForm.get('lastName2').invalid 
                      || clientForm.get('lastName2').errors?.required)"> 
                        <i class="ft-alert-circle align-middle"></i> 
                        This is required 
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row align-items-start mb-2">
                <div class="col-md-8">
                  <div class="form-group mb-2">
                    <label for="email2">Email address</label>
                    <input type="email" class="form-control" id="email2" name="email2" formControlName="email2" 
                    [ngClass]="{ 
                      'is-invalid': ClientFormSubmitted && clientForm.get('email2').invalid,
                      'is-valid': ClientFormSubmitted && !clientForm.get('email2').invalid
                    }"/>
                    
                    <p class="text-muted font-small-3">
                      To grant Client Portal access, enter an email address
                    </p>
        
                    <div class="help-block mt-1 text-danger" 
                    *ngIf="ClientFormSubmitted && (clientForm.get('email2').invalid 
                      || clientForm.get('email2').errors?.required)"> 
                        <i class="ft-alert-circle align-middle"></i> 
                        This is required 
                    </div>
                    
                  </div>
                </div>
                <div class="col-md-4">
                  <label for="emailType2">Type</label>
                  <div class="form-group mb-2">
                    <ng-select id="emailType2" name="emailType2" formControlName="emailType2"
                    class="validate" [ngClass]="{ 
                      'is-invalid ng-touched': ClientFormSubmitted && clientForm.get('emailType2').invalid,
                      'is-valid': ClientFormSubmitted && !clientForm.get('emailType2').invalid
                    }">
                      <ng-option value="Home">Home</ng-option>
                      <ng-option value="Work">Work</ng-option>
                    </ng-select>
        
                    <div class="help-block mt-1 text-danger" 
                    *ngIf="ClientFormSubmitted && (clientForm.get('emailType2').invalid 
                      || clientForm.get('emailType2').errors?.required)"> 
                        <i class="ft-alert-circle align-middle"></i> 
                        This is required 
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="checkbox mb-2">
                    <input class="checkbox" type="checkbox" id="isSendEmailRemainder2" name="isSendEmailRemainder2" formControlName="isSendEmailRemainder2"/>
                    <label class="form-check-label" for="isSendEmailRemainder2" >
                      Send an email reminder 24 hours before the appointment
                    </label>
                  </div>
                </div>
              </div>          
              <div class="form-row align-items-start mb-2">
                <div class="col-md-4">
                  <label for="phone2">Phone Number</label>
                  <div class="form-group mb-2">
                    <input type="phone" class="form-control" name="phone2" id="phone2" formControlName="phone2" 
                    [ngClass]="{ 
                      'is-invalid': ClientFormSubmitted && clientForm.get('phone2').invalid,
                      'is-valid': ClientFormSubmitted && !clientForm.get('phone2').invalid
                    }"/>
        
                    <div class="help-block mt-1 text-danger" 
                    *ngIf="ClientFormSubmitted && (clientForm.get('phone2').invalid 
                      || clientForm.get('phone2').errors?.required)"> 
                        <i class="ft-alert-circle align-middle"></i> 
                        This is required 
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group mb-2">
                    <label for="phoneType2">Type</label>
                    <ng-select name="phoneType2" id="phoneType2" formControlName="phoneType2" 
                    class="validate" [ngClass]="{ 
                      'is-invalid ng-touched': ClientFormSubmitted && clientForm.get('phoneType2').invalid,
                      'is-valid': ClientFormSubmitted && !clientForm.get('phoneType2').invalid
                    }">
                      <ng-option value="Mobile">Mobile</ng-option>
                    </ng-select>
        
                    <div class="help-block mt-1 text-danger" 
                    *ngIf="ClientFormSubmitted && (clientForm.get('phoneType2').invalid 
                      || clientForm.get('phoneType2').errors?.required)"> 
                        <i class="ft-alert-circle align-middle"></i> 
                        This is required 
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="radio mb-1">
                    <input class="radio" type="radio" name="remainderType2" id="textmessage" value="textmessage" formControlName="remainderType2" 
                    [ngClass]="{ 
                      'is-invalid': ClientFormSubmitted && clientForm.get('remainderType2').invalid,
                      'is-valid': ClientFormSubmitted && !clientForm.get('remainderType2').invalid
                    }"/>
                    <label class="form-check-label" for="textmessage">
                      Send a text message reminder 24 hours before appointment
                    </label>
                  </div>
                  <div class="radio">
                    <input class="radio" type="radio" name="remainderType2" id="voicemessage" value="voicemessage" formControlName="remainderType2"
                    [ngClass]="{ 
                      'is-invalid': ClientFormSubmitted && clientForm.get('remainderType2').invalid,
                      'is-valid': ClientFormSubmitted && !clientForm.get('remainderType2').invalid
                    }"/>
                    <label class="form-check-label" for="voicemessage">
                      Send a voice message reminder 24 hours before appointment
                    </label>
                  </div>
                  <div class="help-block mt-1 text-danger" 
                    *ngIf="ClientFormSubmitted && (clientForm.get('remainderType2').invalid 
                      || clientForm.get('remainderType2').errors?.required)"> 
                        <i class="ft-alert-circle align-middle"></i> 
                        Must select one of the above 
                  </div>
                </div>
              </div>
              <div class="form-row align-items-center">
                <div class="col-md-5">
                  <div class="form-group mb-2">
                    <label for="relationship">Contact's relationship to Client</label>
                    <input class="form-control" type="text" name="relationship" id="relationship" formControlName="relationship" 
                    [ngClass]="{ 
                      'is-invalid': ClientFormSubmitted && clientForm.get('relationship').invalid,
                      'is-valid': ClientFormSubmitted && !clientForm.get('relationship').invalid
                    }"/>

                    <div class="help-block mt-1 text-danger" 
                    *ngIf="ClientFormSubmitted && (clientForm.get('relationship').invalid 
                      || clientForm.get('relationship').errors?.required)"> 
                        <i class="ft-alert-circle align-middle"></i> 
                        This is required 
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="checkbox">
                    <input class="checkbox" type="checkbox" name="isResponsibleForBilling" id="isResponsibleForBilling" formControlName="isResponsibleForBilling" />
                    <label class="form-check-label" for="isResponsibleForBilling">
                      Responsible for billing
                    </label>
                  </div>
                </div>
              </div>
            </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="basicnav" class="mt-2"></div>
    </div>
    <div class="mt-0 pt-0 pl-2 pr-2 pb-2" *ngIf="this.clientForm.get('clientType').value === 3">
      <ul ngbNav #basicnav="ngbNav" [(activeId)]="Coupleactive" class="nav-tabs border-0 flx justify-content-end">
        <li [ngbNavItem]="1">
          <a ngbNavLink class="text-uppercase text-dark">Client 1</a>
          <ng-template ngbNavContent>
            <div class="form-row align-items-start mb-2">
              <div class="col-md-6">
                <div class="form-group mb-2">
                  <label for="firstName1">Legal first name</label>
                  <input type="text" class="form-control" id="firstName1" name="firstName1" formControlName="firstName1" 
                  [ngClass]="{ 
                    'is-invalid': ClientFormSubmitted && clientForm.get('firstName1').invalid,
                    'is-valid': ClientFormSubmitted && !clientForm.get('firstName1').invalid
                  }"/>
      
                  <div class="help-block mt-1 text-danger" 
                  *ngIf="ClientFormSubmitted && (clientForm.get('firstName1').invalid 
                    || clientForm.get('firstName1').errors?.required)"> 
                      <i class="ft-alert-circle align-middle"></i> 
                      This is required 
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-2">
                  <label for="lastName1">Legal last name</label>
                  <input type="text" class="form-control" id="lastName1" name="lastName1" formControlName="lastName1"
                  [ngClass]="{ 
                    'is-invalid': ClientFormSubmitted && clientForm.get('lastName1').invalid,
                    'is-valid': ClientFormSubmitted && !clientForm.get('lastName1').invalid
                  }"/>
      
                  <div class="help-block mt-1 text-danger" 
                  *ngIf="ClientFormSubmitted && (clientForm.get('lastName1').invalid 
                    || clientForm.get('lastName1').errors?.required)"> 
                      <i class="ft-alert-circle align-middle"></i> 
                      This is required 
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row align-items-start mb-2">
              <div class="col-md-8">
                <div class="form-group mb-2">
                  <label for="email1">Email address</label>
                  <input type="email" class="form-control" id="email1" name="email1" formControlName="email1" 
                  [ngClass]="{ 
                    'is-invalid': ClientFormSubmitted && clientForm.get('email1').invalid,
                    'is-valid': ClientFormSubmitted && !clientForm.get('email1').invalid
                  }"/>
                  
                  <p class="text-muted font-small-3">
                    To grant Client Portal access, enter an email address
                  </p>
      
                  <div class="help-block mt-1 text-danger" 
                  *ngIf="ClientFormSubmitted && (clientForm.get('email1').invalid 
                    || clientForm.get('email1').errors?.required)"> 
                      <i class="ft-alert-circle align-middle"></i> 
                      This is required 
                  </div>
                  
                </div>
              </div>
              <div class="col-md-4">
                <label for="emailType1">Type</label>
                <div class="form-group mb-2">
                  <ng-select id="emailType1" name="emailType1" formControlName="emailType1"
                  class="validate" [ngClass]="{ 
                    'is-invalid ng-touched': ClientFormSubmitted && clientForm.get('emailType1').invalid,
                    'is-valid': ClientFormSubmitted && !clientForm.get('emailType1').invalid
                  }">
                    <ng-option value="Home">Home</ng-option>
                    <ng-option value="Work">Work</ng-option>
                  </ng-select>
      
                  <div class="help-block mt-1 text-danger" 
                  *ngIf="ClientFormSubmitted && (clientForm.get('emailType1').invalid 
                    || clientForm.get('emailType1').errors?.required)"> 
                      <i class="ft-alert-circle align-middle"></i> 
                      This is required 
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="checkbox mb-2">
                  <input class="checkbox" type="checkbox" id="isSendEmailReminder1" name="isSendEmailReminder1" formControlName="isSendEmailReminder1"/>
                  <label class="form-check-label" for="isSendEmailReminder1" >
                    Send an email reminder 24 hours before the appointment
                  </label>
                </div>
              </div>
            </div>          
            <div class="form-row align-items-start mb-3">
              <div class="col-md-4">
                <label for="phone1">Phone Number</label>
                <div class="form-group mb-2">
                  <input type="phone" class="form-control" name="phone1" id="phone1" formControlName="phone1" 
                  [ngClass]="{ 
                    'is-invalid': ClientFormSubmitted && clientForm.get('phone1').invalid,
                    'is-valid': ClientFormSubmitted && !clientForm.get('phone1').invalid
                  }"/>
      
                  <div class="help-block mt-1 text-danger" 
                  *ngIf="ClientFormSubmitted && (clientForm.get('phone1').invalid 
                    || clientForm.get('phone1').errors?.required)"> 
                      <i class="ft-alert-circle align-middle"></i> 
                      This is required 
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group mb-2">
                  <label for="phoneType1">Type</label>
                  <ng-select name="phoneType1" id="phoneType1" formControlName="phoneType1" 
                  class="validate" [ngClass]="{ 
                    'is-invalid ng-touched': ClientFormSubmitted && clientForm.get('phoneType1').invalid,
                    'is-valid': ClientFormSubmitted && !clientForm.get('phoneType1').invalid
                  }">
                    <ng-option value="Mobile">Mobile</ng-option>
                  </ng-select>
      
                  <div class="help-block mt-1 text-danger" 
                  *ngIf="ClientFormSubmitted && (clientForm.get('phoneType1').invalid 
                    || clientForm.get('phoneType1').errors?.required)"> 
                      <i class="ft-alert-circle align-middle"></i> 
                      This is required 
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="radio mb-1">
                  <input class="radio" type="radio" name="remainderType1" id="textmessage" value="textmessage" formControlName="remainderType1" 
                  [ngClass]="{ 
                    'is-invalid': ClientFormSubmitted && clientForm.get('remainderType1').invalid,
                    'is-valid': ClientFormSubmitted && !clientForm.get('remainderType1').invalid
                  }"/>
                  <label class="form-check-label" for="textmessage">
                    Send a text message reminder 24 hours before appointment
                  </label>
                </div>
                <div class="radio">
                  <input class="radio" type="radio" name="remainderType1" id="voicemessage" value="voicemessage" formControlName="remainderType1"
                  [ngClass]="{ 
                    'is-invalid': ClientFormSubmitted && clientForm.get('remainderType1').invalid,
                    'is-valid': ClientFormSubmitted && !clientForm.get('remainderType1').invalid
                  }"/>
                  <label class="form-check-label" for="voicemessage">
                    Send a voice message reminder 24 hours before appointment
                  </label>
                </div>
                <div class="help-block mt-1 text-danger" 
                  *ngIf="ClientFormSubmitted && (clientForm.get('remainderType1').invalid 
                    || clientForm.get('remainderType1').errors?.required)"> 
                      <i class="ft-alert-circle align-middle"></i> 
                      Must select one of the above 
                </div>
              </div>
            </div>
            <div class="form-row align-items-start">
              <div class="col-md-6">
                <div class="form-group mb-2">
                  <label for="primaryClinicianId">Primary Clinician</label>
                  <ng-select appendTo="body" name="primaryClinicianId" id="primaryClinicianId" formControlName="primaryClinicianId" 
                  class="validate" [ngClass]="{ 
                    'is-invalid ng-touched': ClientFormSubmitted && clientForm.get('primaryClinicianId').invalid,
                    'is-valid': ClientFormSubmitted && !clientForm.get('primaryClinicianId').invalid
                  }">
                    <ng-option [value]="1">Clinician 1</ng-option>
                    <ng-option [value]="2">Clinician 2</ng-option>
                    <ng-option [value]="3">Clinician 3</ng-option>
                  </ng-select>
      
                  <div class="help-block mt-1 text-danger" 
                  *ngIf="ClientFormSubmitted && (clientForm.get('primaryClinicianId').invalid 
                    || clientForm.get('primaryClinicianId').errors?.required)"> 
                      <i class="ft-alert-circle align-middle"></i> 
                      This is required 
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-2">
                  <label for="location">Location</label>
                  <ng-select appendTo="body" name="location" id="location" formControlName="location" 
                  class="validate" [ngClass]="{ 
                    'is-invalid ng-touched': ClientFormSubmitted && clientForm.get('location').invalid,
                    'is-valid': ClientFormSubmitted && !clientForm.get('location').invalid
                  }">
                    <ng-option value="Clinic Visit">Clinic Visit</ng-option>
                    <ng-option value="Voice Call">Voice Call</ng-option>
                    <ng-option value="Video Call">Video Call</ng-option>
                  </ng-select>
      
                  <div class="help-block mt-1 text-danger" 
                  *ngIf="ClientFormSubmitted && (clientForm.get('location').invalid 
                    || clientForm.get('location').errors?.required)"> 
                      <i class="ft-alert-circle align-middle"></i> 
                      This is required 
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink class="text-uppercase text-dark">Client 2</a>
          <ng-template ngbNavContent>
            <div class="form-row align-items-start mb-2">
              <div class="col-md-6">
                <div class="form-group mb-2">
                  <label for="firstName2">Legal first name</label>
                  <input type="text" class="form-control" id="firstName2" name="firstName2" formControlName="firstName2" 
                  [ngClass]="{ 
                    'is-invalid': ClientFormSubmitted && clientForm.get('firstName2').invalid,
                    'is-valid': ClientFormSubmitted && !clientForm.get('firstName2').invalid
                  }"/>
      
                  <div class="help-block mt-1 text-danger" 
                  *ngIf="ClientFormSubmitted && (clientForm.get('firstName2').invalid 
                    || clientForm.get('firstName2').errors?.required)"> 
                      <i class="ft-alert-circle align-middle"></i> 
                      This is required 
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-2">
                  <label for="lastName2">Legal last name</label>
                  <input type="text" class="form-control" id="lastName2" name="lastName2" formControlName="lastName2"
                  [ngClass]="{ 
                    'is-invalid': ClientFormSubmitted && clientForm.get('lastName2').invalid,
                    'is-valid': ClientFormSubmitted && !clientForm.get('lastName2').invalid
                  }"/>
      
                  <div class="help-block mt-1 text-danger" 
                  *ngIf="ClientFormSubmitted && (clientForm.get('lastName2').invalid 
                    || clientForm.get('lastName2').errors?.required)"> 
                      <i class="ft-alert-circle align-middle"></i> 
                      This is required 
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row align-items-start mb-2">
              <div class="col-md-8">
                <div class="form-group mb-2">
                  <label for="email2">Email address</label>
                  <input type="email" class="form-control" id="email2" name="email2" formControlName="email2" 
                  [ngClass]="{ 
                    'is-invalid': ClientFormSubmitted && clientForm.get('email2').invalid,
                    'is-valid': ClientFormSubmitted && !clientForm.get('email2').invalid
                  }"/>
                  
                  <p class="text-muted font-small-3">
                    To grant Client Portal access, enter an email address
                  </p>
      
                  <div class="help-block mt-1 text-danger" 
                  *ngIf="ClientFormSubmitted && (clientForm.get('email2').invalid 
                    || clientForm.get('email2').errors?.required)"> 
                      <i class="ft-alert-circle align-middle"></i> 
                      This is required 
                  </div>
                  
                </div>
              </div>
              <div class="col-md-4">
                <label for="emailType2">Type</label>
                <div class="form-group mb-2">
                  <ng-select id="emailType2" name="emailType2" formControlName="emailType2"
                  class="validate" [ngClass]="{ 
                    'is-invalid ng-touched': ClientFormSubmitted && clientForm.get('emailType2').invalid,
                    'is-valid': ClientFormSubmitted && !clientForm.get('emailType2').invalid
                  }">
                    <ng-option value="Home">Home</ng-option>
                    <ng-option value="Work">Work</ng-option>
                  </ng-select>
      
                  <div class="help-block mt-1 text-danger" 
                  *ngIf="ClientFormSubmitted && (clientForm.get('emailType2').invalid 
                    || clientForm.get('emailType2').errors?.required)"> 
                      <i class="ft-alert-circle align-middle"></i> 
                      This is required 
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="checkbox mb-2">
                  <input class="checkbox" type="checkbox" id="isSendEmailRemainder2" name="isSendEmailRemainder2" formControlName="isSendEmailRemainder2"/>
                  <label class="form-check-label" for="isSendEmailRemainder2" >
                    Send an email reminder 24 hours before the appointment
                  </label>
                </div>
              </div>
            </div>          
            <div class="form-row align-items-start mb-3">
              <div class="col-md-4">
                <label for="phone2">Phone Number</label>
                <div class="form-group mb-2">
                  <input type="phone" class="form-control" name="phone2" id="phone2" formControlName="phone2" 
                  [ngClass]="{ 
                    'is-invalid': ClientFormSubmitted && clientForm.get('phone2').invalid,
                    'is-valid': ClientFormSubmitted && !clientForm.get('phone2').invalid
                  }"/>
      
                  <div class="help-block mt-1 text-danger" 
                  *ngIf="ClientFormSubmitted && (clientForm.get('phone2').invalid 
                    || clientForm.get('phone2').errors?.required)"> 
                      <i class="ft-alert-circle align-middle"></i> 
                      This is required 
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group mb-2">
                  <label for="phoneType2">Type</label>
                  <ng-select name="phoneType2" id="phoneType2" formControlName="phoneType2" 
                  class="validate" [ngClass]="{ 
                    'is-invalid ng-touched': ClientFormSubmitted && clientForm.get('phoneType2').invalid,
                    'is-valid': ClientFormSubmitted && !clientForm.get('phoneType2').invalid
                  }">
                    <ng-option value="Mobile">Mobile</ng-option>
                  </ng-select>
      
                  <div class="help-block mt-1 text-danger" 
                  *ngIf="ClientFormSubmitted && (clientForm.get('phoneType2').invalid 
                    || clientForm.get('phoneType2').errors?.required)"> 
                      <i class="ft-alert-circle align-middle"></i> 
                      This is required 
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="radio mb-1">
                  <input class="radio" type="radio" name="remainderType2" id="textmessage" value="textmessage" formControlName="remainderType2" 
                  [ngClass]="{ 
                    'is-invalid': ClientFormSubmitted && clientForm.get('remainderType2').invalid,
                    'is-valid': ClientFormSubmitted && !clientForm.get('remainderType2').invalid
                  }"/>
                  <label class="form-check-label" for="textmessage">
                    Send a text message reminder 24 hours before appointment
                  </label>
                </div>
                <div class="radio">
                  <input class="radio" type="radio" name="remainderType2" id="voicemessage" value="voicemessage" formControlName="remainderType2"
                  [ngClass]="{ 
                    'is-invalid': ClientFormSubmitted && clientForm.get('remainderType2').invalid,
                    'is-valid': ClientFormSubmitted && !clientForm.get('remainderType2').invalid
                  }"/>
                  <label class="form-check-label" for="voicemessage">
                    Send a voice message reminder 24 hours before appointment
                  </label>
                </div>
                <div class="help-block mt-1 text-danger" 
                  *ngIf="ClientFormSubmitted && (clientForm.get('remainderType2').invalid 
                    || clientForm.get('remainderType2').errors?.required)"> 
                      <i class="ft-alert-circle align-middle"></i> 
                      Must select one of the above 
                </div>
              </div>
            </div>
            <div class="form-row align-items-start">
              <div class="col-md-6">
                <div class="form-group mb-2">
                  <label for="primaryClinicianId2">Primary Clinician</label>
                  <ng-select appendTo="body" name="primaryClinicianId2" id="primaryClinicianId2" formControlName="primaryClinicianId2" 
                  class="validate" [ngClass]="{ 
                    'is-invalid ng-touched': ClientFormSubmitted && clientForm.get('primaryClinicianId2').invalid,
                    'is-valid': ClientFormSubmitted && !clientForm.get('primaryClinicianId2').invalid
                  }">
                    <ng-option [value]="1">Clinician 1</ng-option>
                    <ng-option [value]="2">Clinician 2</ng-option>
                    <ng-option [value]="3">Clinician 3</ng-option>
                  </ng-select>
      
                  <div class="help-block mt-1 text-danger" 
                  *ngIf="ClientFormSubmitted && (clientForm.get('primaryClinicianId2').invalid 
                    || clientForm.get('primaryClinicianId2').errors?.required)"> 
                      <i class="ft-alert-circle align-middle"></i> 
                      This is required 
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-2">
                  <label for="location2">Location</label>
                  <ng-select appendTo="body" name="location2" id="location2" formControlName="location2" 
                  class="validate" [ngClass]="{ 
                    'is-invalid ng-touched': ClientFormSubmitted && clientForm.get('location2').invalid,
                    'is-valid': ClientFormSubmitted && !clientForm.get('location2').invalid
                  }">
                    <ng-option value="Clinic Visit">Clinic Visit</ng-option>
                    <ng-option value="Voice Call">Voice Call</ng-option>
                    <ng-option value="Video Call">Video Call</ng-option>
                  </ng-select>
      
                  <div class="help-block mt-1 text-danger" 
                  *ngIf="ClientFormSubmitted && (clientForm.get('location2').invalid 
                    || clientForm.get('location2').errors?.required)"> 
                      <i class="ft-alert-circle align-middle"></i> 
                      This is required 
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="3">
          <a ngbNavLink class="text-uppercase text-dark">Billing</a>
          <ng-template ngbNavContent>
              <div class="form-row align-items-center">
                <div class="col-md-12">
                  <label class="mb-1" for="paymentType">Billing Type</label>
                  <div class="help-block mt-1 mb-2 text-danger" 
                    *ngIf="ClientFormSubmitted && (clientForm.get('paymentType').invalid 
                      || clientForm.get('paymentType').errors?.required)"> 
                        <i class="ft-alert-circle align-middle"></i> 
                        Must select one of the Below 
                  </div>
                  <div class="radio mb-1">
                    <input class="radio" type="radio" name="paymentType" id="Self-pay" value="Self-pay" formControlName="paymentType" 
                    [ngClass]="{ 
                      'is-invalid': ClientFormSubmitted && clientForm.get('paymentType').invalid,
                      'is-valid': ClientFormSubmitted && !clientForm.get('paymentType').invalid
                    }"/>
                    <label class="form-check-label" for="Self-pay">
                      Self-pay
                    </label>
                  </div>
                  <div class="radio mb-3">
                    <input class="radio" type="radio" name="paymentType" id="insurance" value="insurance" formControlName="paymentType" 
                    [ngClass]="{ 
                      'is-invalid': ClientFormSubmitted && clientForm.get('paymentType').invalid,
                      'is-valid': ClientFormSubmitted && !clientForm.get('paymentType').invalid
                    }"/>
                    <label class="form-check-label" for="insurance">
                      Insurance
                    </label>
                  </div>
                </div>
                <div class="col-md-12 mb-3 border-bottom" *ngIf="this.clientForm.get('paymentType').value === 'insurance' ">
                  <!-- Will Apper when clicked on insurance radio button -->
                  <div class="mb-3">
                    <ng-select appendTo="body" name="insuranceId" id="insuranceId" formControlName="insuranceId" 
                    class="validate" [ngClass]="{ 
                      'is-invalid ng-touched': ClientFormSubmitted && clientForm.get('insuranceId').invalid,
                      'is-valid': ClientFormSubmitted && !clientForm.get('insuranceId').invalid
                    }">
                      <ng-option [value]="1">Insurance 1</ng-option>
                      <ng-option [value]="2">Insurance 2</ng-option>
                      <ng-option [value]="3">Insurance 3</ng-option>
                    </ng-select>
        
                    <div class="help-block mt-1 text-danger" 
                    *ngIf="ClientFormSubmitted && (clientForm.get('insuranceId').invalid 
                      || clientForm.get('insuranceId').errors?.required)"> 
                        <i class="ft-alert-circle align-middle"></i> 
                        This is required 
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-2">
                    <label>Responsible for billing :</label>
                    <ng-select appendTo="body" name="billResponsibleClient" id="billResponsibleClient" formControlName="billResponsibleClient" 
                    class="validate" [ngClass]="{ 
                      'is-invalid ng-touched': ClientFormSubmitted && clientForm.get('billResponsibleClient').invalid,
                      'is-valid': ClientFormSubmitted && !clientForm.get('billResponsibleClient').invalid
                    }">
                      <ng-option value="Client 1">Client 1</ng-option>
                      <ng-option value="Client 2">Client 2</ng-option>
                    </ng-select>

                    <div class="help-block mt-1 text-danger" 
                    *ngIf="ClientFormSubmitted && (clientForm.get('billResponsibleClient').invalid 
                      || clientForm.get('billResponsibleClient').errors?.required)"> 
                        <i class="ft-alert-circle align-middle"></i> 
                        This is required 
                    </div>
                  </div>
                </div>
              </div>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="basicnav" class="mt-2"></div>
    </div>
  </div>
</div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close')">Close</button>
  	<button type="submit" class="btn btn-outline-success" [disabled]="btnDisable">{{btnText}}</button>
  </div>
</form>